import { FC, useEffect, useId } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { openDevice, reset } from '../app/euiccSlice'
import { useAppDispatch } from '../app/hooks'

export const Layout: FC = () => {
  const { t } = useTranslation()
  const toggleId = useId()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!('usb' in navigator)) return
    const handleDisconnect = () => dispatch(reset())
    navigator.usb.addEventListener('disconnect', handleDisconnect)
    return () => {
      navigator.usb.removeEventListener('disconnect', handleDisconnect)
    }
  }, [dispatch])
  return (
    <>
      <Navbar className='bg-body-tertiary'>
        <Container>
          <Navbar.Brand as={Link} tabIndex={1} to='/'>
            {t('product.name')}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={toggleId} />
          <Navbar.Collapse className='justify-content-start' id={toggleId}>
            <Nav className='me-auto'>
              <Nav.Link as={NavLink} tabIndex={2} to='/profiles'>
                {t('profiles')}
              </Nav.Link>
              <Nav.Link as={NavLink} tabIndex={3} to='/notifications'>
                {t('notifications')}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className='justify-content-end'>
            <NavDropdown title={t('open-device')}>
              <NavDropdown.Item onClick={() => dispatch(openDevice('USB'))}>{t('open-device.webusb')}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => dispatch(openDevice('BLE'))}>{t('open-device.webble')}</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container as='main'>
        <Outlet />
      </Container>
    </>
  )
}
