import 'bootstrap/dist/css/bootstrap.min.css'
import ready from 'domready'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { store } from './app/store'
import { i18n } from './i18n'
import { ModalProvider } from './providers/ModalProvider'
import { routes } from './routes'

const entry = (
  <I18nextProvider i18n={i18n}>
    <ReduxProvider store={store}>
      <ModalProvider>
        <RouterProvider router={routes} />
      </ModalProvider>
    </ReduxProvider>
  </I18nextProvider>
)

ready(() => {
  const container = document.createElement('main')

  document.body = document.createElement('body')
  document.body.appendChild(container)

  createRoot(container).render(entry)
})
