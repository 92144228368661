import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, ExtraArgument, RootState } from './store'

export interface AsyncThunkConfig {
  state: RootState
  dispatch: AppDispatch
  extra: ExtraArgument
  rejectValue?: unknown
  serializedErrorType?: unknown
  pendingMeta?: unknown
  fulfilledMeta?: unknown
  rejectedMeta?: unknown
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<AsyncThunkConfig>()

export type ThunkAction = ThunkAction.Type

export namespace ThunkAction {
  type Thunk = ReturnType<typeof createAppAsyncThunk>
  export type Type = Pending | Fulfilled | Rejected
  export type Pending = ReturnType<Thunk['pending']>
  export type Rejected = ReturnType<Thunk['rejected']>
  export type Fulfilled = ReturnType<Thunk['fulfilled']>
}
