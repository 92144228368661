import type { Resource } from 'i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import localeEnglish from './locales/en-US.json'

const resources: Resource = {
  en: { translation: localeEnglish },
}

i18n.use(initReactI18next).init({ resources, lng: 'en' })

export { i18n }
