import { createHashRouter, Navigate } from 'react-router-dom'
import { Layout } from './components/Layout'

export const routes = createHashRouter([
  {
    path: '/',
    Component: Layout,
    children: [
      {
        index: true,
        element: <Navigate to='profiles' replace />,
      },
      {
        path: 'profiles',
        lazy: () => import(/* webpackChunkName: "profiles" */ './components/ProfileList'),
      },
      {
        path: 'notifications',
        lazy: () => import(/* webpackChunkName: "notifications" */ './components/NotificationList'),
      },
    ],
  },
])
