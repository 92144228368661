/// <reference types="@types/w3c-web-usb" />
import type { Backend } from '../types'
import { getUint16 } from '../utils'

const FILTERS: USBDeviceFilter[] = [
  {
    vendorId: 0x076b,
    productId: 0x3a21,
    classCode: 0xff,
  },
]

const SEGMENT_SIZE = 2 << 13 // 16 KiB

export class WebUSBBackend implements Backend {
  private readonly device: USBDevice
  private readonly abortController = new AbortController()

  static async requestDevice() {
    if (!(await this.getAvailability())) throw new Error('WebUSB is not available')
    return navigator.usb.requestDevice({ filters: FILTERS })
  }

  static async getAvailability() {
    return 'usb' in navigator
  }

  static async getDevices() {
    const devices = await navigator.usb.getDevices()
    return devices.filter((device) => !device.opened && device.productName === 'ESTKme-RED')
  }

  static async open(device: USBDevice, interfaceNumber = 1) {
    if (device === undefined) throw new Error('No device selected')
    await device.open()
    await device.claimInterface(interfaceNumber)
    return new this(device)
  }

  private constructor(device: USBDevice) {
    this.device = device
    navigator.usb.addEventListener('disconnect', this)
  }

  get connected(): boolean {
    return this.device.opened
  }

  async handleEvent(event: USBConnectionEvent) {
    if (event.device !== this.device) return
    if (event.type === 'disconnect') {
      this.abortController.abort('disconnect')
      navigator.usb.removeEventListener('disconnect', this)
    }
  }

  async invoke(request: Uint8Array): Promise<Uint8Array> {
    const response = new Uint8Array(0xffff)
    // transmit request
    await this.write(0x00, request)
    await this.read(0x01, response, request.byteLength)
    if (!equals(request, response)) throw new Error('The request is mismatched')
    // receive response
    await this.read(0x02, response, 2)
    await this.read(0x03, response, getUint16(response, 0))
    return response.slice(0, getUint16(response, 3))
  }

  async close(options?: Backend.CloseOptions) {
    this.abortController.abort('close')
    navigator.usb.removeEventListener('disconnect', this)
    await this.device.releaseInterface(1)
    await this.device.close()
    if (options?.forget) await this.device.forget()
    return
  }

  [Symbol.asyncDispose]() {
    return this.close()
  }

  get [Symbol.toStringTag](): string {
    return 'WebUSBBackend'
  }

  toString(): string {
    return `WebUSB:${this.device.serialNumber}`
  }

  private async read(request: number, response: Uint8Array, length = response.byteLength) {
    let offset = 0
    let chunkSize: number
    let result: USBInTransferResult
    while (offset < length) {
      this.abortController.signal.throwIfAborted()
      chunkSize = Math.min(SEGMENT_SIZE, length - offset)
      result = await this.device.controlTransferIn(this.setup(request, offset), chunkSize)
      if (result.status !== 'ok' || !result.data) throw new Error(`Failed to read packet: ${result.status}`)
      response.set(new Uint8Array(result.data.buffer), offset)
      offset += result.data.byteLength
    }
  }

  private async write(request: number, packet: Uint8Array) {
    let offset = 0
    let chunk: Uint8Array
    let result: USBOutTransferResult
    while (offset < packet.byteLength) {
      this.abortController.signal.throwIfAborted()
      chunk = packet.slice(offset, offset + SEGMENT_SIZE)
      result = await this.device.controlTransferOut(this.setup(request, offset), chunk)
      if (result.status !== 'ok') throw new Error(`Failed to write packet: ${result.status}`)
      offset += result.bytesWritten
    }
  }

  private setup(request: number, value: number): USBControlTransferParameters {
    return { requestType: 'vendor', recipient: 'interface', request, value: 0, index: 1 }
  }
}

function equals(a: Uint8Array, b: Uint8Array) {
  for (let offset = 0; offset < a.byteLength; offset++) {
    if (a[offset] !== b[offset]) return false
  }
  return true
}
