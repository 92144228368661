import { Mutex } from 'async-mutex'
import { Request, Response } from './APDU'
import { Command, CommandType } from './Command'
import { NotCCIDError } from './NotCCIDError'
import { NotCCIDStatus } from './NotCCIDStatus'
import type { Backend, RGB } from './types'
import { equals, toRGB } from './utils'

/* b'ESTKme' */
const CLAIM_MAGIC_DATA = Uint8Array.of(0x45, 0x53, 0x54, 0x4b, 0x6d, 0x65)

export class NotCCID {
  readonly #mutex = new Mutex()
  readonly #backend: Backend
  #cardInserted = false
  #claimed = false
  #atr: Uint8Array | undefined

  constructor(backend: Backend) {
    this.#backend = backend
  }

  protected async invoke(type: CommandType, payload = new Uint8Array(0)): Promise<Uint8Array> {
    const release = await this.#mutex.acquire()
    try {
      const request = new Command(type, payload)
      const response = Command.from(await this.#backend.invoke(request.valueOf()))
      if (request.type !== response.type) throw new NotCCIDError(`Unexpected response type: ${response.type}`)
      return response.payload
    } finally {
      release()
    }
  }

  get claimed() {
    return this.#claimed
  }

  get powered() {
    return this.#atr !== undefined
  }

  get cardInserted() {
    return this.#cardInserted
  }

  get cardRemoved() {
    return !this.#cardInserted
  }

  /** Answer To Reset */
  get atr() {
    if (this.#atr === undefined) throw new NotCCIDError('Not powered')
    return Uint8Array.from(this.#atr)
  }

  /** Status */
  async getStatus(): Promise<NotCCIDStatus> {
    const status = new NotCCIDStatus(await this.invoke(CommandType.Status))
    this.#claimed = status.claimed
    this.#cardInserted = status.cardInserted
    if (!status.claimed || !status.cardInserted) {
      this.#atr = undefined
    }
    return status
  }

  /**
   * Emit RGB LED Indicator
   * @param rgb RGB Color (if unset, turn off)
   */
  async emitLED(rgb?: RGB) {
    return await this.invoke(CommandType.EmitLED, rgb ? toRGB(rgb) : undefined)
  }

  /** Claim */
  async claim() {
    await this.invoke(CommandType.Claim, CLAIM_MAGIC_DATA)
    this.#claimed = true
  }

  /** Release */
  async release() {
    this.assertClaim()
    await this.invoke(CommandType.Claim)
    this.#claimed = false
  }

  /**
   * Power On Card
   * @param negotiation Auto-Negotiation Max Speed
   * @returns Answer To Reset
   */
  async powerOnCard(negotiation = true) {
    await this.getStatus()
    if (!this.cardInserted) throw new Error('No card inserted')
    const response = await this.invoke(CommandType.Power, Uint8Array.of(0x01, negotiation ? 0x01 : 0x00))
    if (equals(response, Uint8Array.of(0xff))) {
      this.#atr = undefined
      throw new Error('The card is not responding')
    }
    this.#atr = response
    return response
  }

  /** Power Off Card */
  async powerOffCard() {
    if (this.#atr === undefined) return
    const response = await this.invoke(CommandType.Power)
    this.#atr = undefined
    return response
  }

  /** Transmit APDU */
  async transmit(request: Uint8Array | Iterable<number>): Promise<Response> {
    this.assertClaim()
    this.assertPowerOn()
    return new Response(await this.invoke(CommandType.Transmit, Uint8Array.from(request)))
  }

  /** Enter eSTK.me Recovery Mode */
  async enterRecoveryMode() {
    this.assertClaim()
    this.assertPowerOn()
    return await this.invoke(CommandType.eSTKmeRecovery)
  }

  /** Close */
  async close(options?: Backend.CloseOptions) {
    await this.#backend.close(options)
    this.#claimed = false
    this.#atr = undefined
  }

  private assertClaim(): asserts this is { claimed: true } {
    if (this.claimed) return
    throw new NotCCIDError('Not claimed')
  }

  private assertPowerOn(): asserts this is { atr: Uint8Array } {
    if (this.#atr !== undefined) return
    throw new NotCCIDError('Not powered')
  }
}
