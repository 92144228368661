export class Request extends Uint8Array {
  public static make: Request.Maker = (cla, ins, p1, p2, data?: number | Uint8Array, le?: number) =>
    new Request(requestMaker(cla, ins, p1, p2, data, le))

  get header() {
    return Uint8Array.from(this.slice(0, 4))
  }

  get CLA() {
    return this[0]
  }

  get INS() {
    return this[1]
  }

  get P1() {
    return this[2]
  }

  get P2() {
    return this[3]
  }

  get Lc(): number | undefined {
    return this[4]
  }

  get data() {
    if (!this.Lc || this.byteLength !== 5 + this.Lc) return
    return Uint8Array.from(this.slice(5, 5 + this.Lc))
  }

  get Le() {
    if (!this.Lc || this.byteLength === 5 + this.Lc) return
    return this.at(-1)
  }
}

export namespace Request {
  export interface Maker {
    (CLA: number, INS: number, P1: number, P2: number): Request
    (CLA: number, INS: number, P1: number, P2: number, Le: number): Request
    (CLA: number, INS: number, P1: number, P2: number, DATA: Uint8Array): Request
    (CLA: number, INS: number, P1: number, P2: number, DATA: Uint8Array, Le: number): Request
  }
}

function* requestMaker(cla: number, ins: number, p1: number, p2: number, data?: number | Uint8Array, le?: number) {
  yield cla
  yield ins
  yield p1
  yield p2
  if (typeof data === 'number') {
    yield data // Le
  } else if (data !== undefined) {
    if (data.byteLength > 0xff) throw new Error('data too long')
    yield data.byteLength // Lc
    yield* data // DATA
    if (le !== undefined) yield le // Le
  }
}

export class Response extends Uint8Array {
  public static make: Response.Maker = (data: number | Uint8Array, sw1?: number, sw2?: number) => {
    let _data = new Uint8Array()
    let _sw: Uint8Array | undefined
    if (typeof data === 'number' && sw2 === undefined) {
      _sw = sw1 === undefined ? Uint8Array.of(data >> 8, data) : Uint8Array.of(data, sw1)
    } else if (typeof data !== 'number' && typeof sw1 === 'number') {
      _data = data
      _sw = sw2 === undefined ? Uint8Array.of(sw1 >> 8, sw1) : Uint8Array.of(sw1, sw2)
    }
    if (_sw === undefined) throw new Error('Invalid arguments')
    const response = new Response(_data.byteLength + _sw.byteLength)
    response.set(_data, 0)
    response.set(_sw, _data.byteLength)
    return response
  }

  get data() {
    return Uint8Array.from(this.slice(0, -2))
  }

  get sw() {
    return (this.sw1 << 8) | this.sw2
  }

  get sw1() {
    return this.at(-2)!
  }

  get sw2() {
    return this.at(-1)!
  }

  get ok() {
    return this.sw === 0x9000
  }

  get hasMore() {
    return this.sw1 == 0x61
  }

  get isWrongLength() {
    return this.sw1 == 0x6c
  }
}

export namespace Response {
  export interface Maker {
    (sw: number): Response
    (sw1: number, sw2: number): Response
    (data: Uint8Array, sw: number): Response
    (data: Uint8Array, sw1: number, sw2: number): Response
  }
}
