export function concat(...chunks: readonly Uint8Array[]) {
  const totalLength = chunks.reduce((length, chunk) => length + chunk.byteLength, 0)
  const result = new Uint8Array(totalLength)
  let offset = 0
  for (const chunk of chunks) {
    result.set(chunk, offset)
    offset += chunk.byteLength
  }
  return result
}

export function fromHexString(input: string): Uint8Array {
  if (input.length % 2 !== 0) throw new Error('Invalid hex string')
  const chunk = new Uint8Array(input.length / 2)
  for (let index = 0; index < input.length; index += 2) {
    chunk[index / 2] = Number.parseInt(input.slice(index, index + 2), 16)
  }
  return chunk
}

export function toHexString(data: Uint8Array): string {
  return Array.from(data)
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
}

export function equals(a: BufferSource, b: BufferSource) {
  if (a.byteLength !== b.byteLength) return false
  const x = new Uint8Array(toArrayBuffer(a))
  const y = new Uint8Array(toArrayBuffer(b))
  for (let index = 0; index < x.length; index++) {
    if (x[index] !== y[index]) return false
  }
  return true
}

export function toArrayBuffer(source: BufferSource) {
  return 'buffer' in source ? source.buffer : source
}
