import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActivationCode } from '../components/ProfileList/ActivationCode'
import { ChipInfo, EUICCService, Notification, Profile } from '../service/EUICCService'
import { WebBLEBackend, WebUSBBackend } from '../service/NotCCID'
import { createAppAsyncThunk, ThunkAction } from './thunk'

interface State {
  readonly pending: boolean
  readonly atr: string | null
  readonly chipInfo: ChipInfo | null
  readonly profiles: readonly Profile[]
  readonly notifications: readonly Notification[]
  readonly notificationSents: readonly number[]
}

const initialState: State = {
  pending: false,
  atr: null,
  chipInfo: null,
  profiles: [],
  notifications: [],
  notificationSents: [],
}

export const euiccSlice = createSlice({
  name: 'euicc',
  initialState,
  reducers: {
    setATR(state, action: PayloadAction<string>) {
      return { ...state, atr: action.payload }
    },
    setChipInfo(state, action: PayloadAction<ChipInfo>) {
      return { ...state, chipInfo: action.payload }
    },
    setProfileList(state, action: PayloadAction<readonly Profile[]>) {
      return { ...state, profiles: action.payload }
    },
    addProfile(state, action: PayloadAction<Profile>) {
      return { ...state, profiles: [...state.profiles, action.payload] }
    },
    replaceProfile(state, action: PayloadAction<Profile>) {
      const profiles = state.profiles.map((profile) => {
        if (profile.iccid === action.payload.iccid) {
          return action.payload
        }
        return profile
      })
      return { ...state, profiles }
    },
    setNotificationList(state, action: PayloadAction<readonly Notification[]>) {
      return { ...state, notifications: action.payload }
    },
    setSentNotification(state, action: PayloadAction<Notification>) {
      const notificationSents = new Set(state.notificationSents)
      notificationSents.add(action.payload.seqNumber)
      return { ...state, notificationSents: Array.from(notificationSents) }
    },
    addNotification(state, action: PayloadAction<Notification>) {
      return { ...state, notifications: [...state.notifications, action.payload] }
    },
    removeNotification(state, action: PayloadAction<Notification>) {
      const notifications = state.notifications.filter(({ seqNumber }) => seqNumber !== action.payload.seqNumber)
      const notificationSents = state.notificationSents.filter((seqNumber) => seqNumber !== action.payload.seqNumber)
      return { ...state, notifications, notificationSents }
    },
    reset() {
      return initialState
    },
  },
  selectors: {
    selectPending(state) {
      return state.pending
    },
    selectATR(state) {
      return state.atr
    },
    selectChipInfo(state) {
      return state.chipInfo
    },
    selectProfileList(state) {
      return state.profiles
    },
    selectProfile(state, iccid: string) {
      return state.profiles.find((profile) => profile.iccid === iccid)
    },
    selectNotificationList(state) {
      return state.notifications
    },
    selectNotificationSents(state) {
      return state.notificationSents
    },
    selectNotification(state, index: number) {
      return state.notifications.find((notification) => notification.seqNumber === index)
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      ({ type }: ThunkAction) => type.startsWith('euicc/'),
      (state, { type }) => ({ ...state, pending: type.endsWith('/pending') }),
    )
  },
})

export const {
  selectPending,
  selectATR,
  selectProfile,
  selectProfileList,
  selectChipInfo,
  selectNotificationList,
  selectNotificationSents,
} = euiccSlice.selectors

const { selectors, actions } = euiccSlice

export const { reset } = euiccSlice.actions

export const openDevice = createAppAsyncThunk('euicc/open', async (method: 'USB' | 'BLE', { dispatch, extra }) => {
  switch (method) {
    case 'USB':
      extra.setEUICCBackend(await WebUSBBackend.open(await WebUSBBackend.requestDevice()))
      break
    case 'BLE':
      extra.setEUICCBackend(await WebBLEBackend.open(await WebBLEBackend.requestDevice()))
      break
    default:
      return
  }
  const service = extra.getEUICCService()
  if (service === null) return
  await service.connect()
  dispatch(actions.setATR(service.atr))
  await dispatch(fetchChipInfo())
  await dispatch(fetchProfileList())
})

export const connect = createAppAsyncThunk('euicc/connect', async (_: void, { dispatch, extra }) => {
  let service: EUICCService | null
  try {
    service = extra.getEUICCService()
    if (service !== null) service.disconnect()
    const devices = await WebUSBBackend.getDevices()
    if (devices.length === 0) return
    extra.setEUICCBackend(await WebUSBBackend.open(devices[0]))
    service = extra.getEUICCService()
    if (service === null) return
    await service.connect()
    dispatch(actions.setATR(service.atr))
    await dispatch(fetchChipInfo())
    await dispatch(fetchProfileList())
  } catch {
    // ignore
  }
})

export const disconnect = createAppAsyncThunk('euicc/disconnect', async (_: void, { dispatch, extra }) => {
  try {
    const service = extra.getEUICCService()
    if (service === null) return
    await service.disconnect()
    dispatch(euiccSlice.actions.reset())
  } catch {
    // ignore
  }
})

export const fetchChipInfo = createAppAsyncThunk('euicc/chip-info', async (_: void, { dispatch, extra }) => {
  const service = extra.getEUICCService()
  if (service === null) return
  const chipInfo = await service.chipInfo()
  dispatch(actions.setChipInfo(chipInfo))
})

export const fetchProfileList = createAppAsyncThunk('euicc/profile/fetchList', async (_: void, { dispatch, extra }) => {
  const service = extra.getEUICCService()
  if (service === null) return
  const profiles = await Array.fromAsync(service.listProfile())
  dispatch(actions.setProfileList(profiles))
  await dispatch(fetchNotificationList())
})

export const downloadProfile = createAppAsyncThunk(
  'euicc/profile/download',
  async function* (activationCode: ActivationCode, { dispatch, extra }) {
    const service = extra.getEUICCService()
    if (service === null) return
    for await (const progress of service.downloadProfile(activationCode)) {
      yield progress
    }
    await dispatch(fetchProfileList())
    await dispatch(sendLastNotification())
  },
)

export const enableProfile = createAppAsyncThunk(
  'euicc/profile/enable',
  async (profile: Profile, { dispatch, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    await service.enableProfile(profile)
    dispatch(actions.replaceProfile({ ...profile, profileState: 'enabled' }))
    await dispatch(fetchProfileList())
    await dispatch(sendLastNotification())
  },
)

export const disableProfile = createAppAsyncThunk(
  'euicc/profile/disable',
  async (profile: Profile, { dispatch, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    await service.disableProfile(profile)
    dispatch(actions.replaceProfile({ ...profile, profileState: 'disabled' }))
    await dispatch(fetchProfileList())
    await dispatch(sendLastNotification())
  },
)

export const deleteProfile = createAppAsyncThunk(
  'euicc/profile/delete',
  async (profile: Profile, { dispatch, getState, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    await service.deleteProfile(profile)
    const profiles = getState().euicc.profiles
    dispatch(actions.setProfileList(profiles.filter((p) => p.iccid !== profile.iccid)))
    await dispatch(fetchProfileList())
    await dispatch(sendLastNotification())
  },
)

interface RenameProfilePayload {
  readonly profile: Profile
  readonly name: string
}

export const renameProfile = createAppAsyncThunk(
  'euicc/profile/rename',
  async ({ profile, name }: RenameProfilePayload, { dispatch, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    if (profile.profileNickname === name) return
    await service.setProfileName(profile, name)
    dispatch(actions.replaceProfile({ ...profile, profileName: name }))
    await dispatch(fetchProfileList())
  },
)

export const discoveryProfile = createAppAsyncThunk('euicc/profile/discovery', async (_: void, { dispatch, extra }) => {
  const service = extra.getEUICCService()
  if (service === null) return
  console.log(await service.discoveryProfile())
})

export const purge = createAppAsyncThunk('euicc/profile/delete', async (_: void, { dispatch, extra }) => {
  const service = extra.getEUICCService()
  if (service === null) return
  await service.purge()
  await dispatch(fetchProfileList())
})

export const fetchNotificationList = createAppAsyncThunk(
  'euicc/notification/fetchList',
  async (_: void, { dispatch, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    const notifications = await Array.fromAsync(service.listNotification())
    dispatch(actions.setNotificationList(notifications))
  },
)

export const sendNotificationList = createAppAsyncThunk(
  'euicc/notification/sendList',
  async (notifications: readonly Notification[], { dispatch, getState, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    const notificationSents = new Set(selectors.selectNotificationSents(getState()))
    const sendableNotifications = notifications.filter((notification) => {
      return !notificationSents.has(notification.seqNumber)
    })
    for await (const notification of service.sendNotificationList(sendableNotifications)) {
      dispatch(actions.setSentNotification(notification))
    }
    await dispatch(fetchNotificationList())
  },
)

export const removeNotificationList = createAppAsyncThunk(
  'euicc/notification/removeList',
  async (notifications: readonly Notification[], { dispatch, getState, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    const notificationSents = new Set(selectors.selectNotificationSents(getState()))
    const deletableNotifications = notifications.filter(({ seqNumber }) => notificationSents.has(seqNumber))
    for await (const notification of service.removeNotificationList(deletableNotifications)) {
      dispatch(actions.removeNotification(notification))
    }
    await dispatch(fetchNotificationList())
  },
)

export const sendLastNotification = createAppAsyncThunk(
  'euicc/notification/sendLast',
  async (_: void, { getState, dispatch, extra }) => {
    const service = extra.getEUICCService()
    if (service === null) return
    await dispatch(fetchNotificationList())
    const { notifications } = getState().euicc
    if (notifications.length === 0) return
    const lastNotification = notifications.at(-1)!
    for await (const notification of service.sendNotificationList([lastNotification])) {
      dispatch(actions.setSentNotification(notification))
    }
  },
)
