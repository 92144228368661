import { Request } from '../APDU'
import type { NotCCID } from '../NotCCID'
import { FirmwareUpgrade } from './types'

interface FirmwareUpgradeFactory {
  new (ccid: NotCCID): FirmwareUpgrade
}

export class DFUMode implements AsyncDisposable {
  readonly ccid: NotCCID

  constructor(ccid: NotCCID) {
    this.ccid = ccid
  }

  async getBootloaderVersion() {
    const response = await this.ccid.transmit(Request.make(0xaa, 0xff, 0x00, 0x00, 0x08))
    return String.fromCharCode.apply(null, Array.from(response.data))
  }

  async upgrade<T extends FirmwareUpgradeFactory>(FWUPD: T, bundle: Uint8Array) {
    const fwupd = new FWUPD(this.ccid)
    await fwupd.unlock()
    await fwupd.program(bundle)
    await fwupd.leave()
  }

  async enter() {
    if (!this.ccid.powered) throw new Error('Card not powered')
    // select firmware upgrade application
    // prettier-ignore
    const magic = Uint8Array.of(
      0xa0, 0x65, 0x73, 0x74, 0x6b, 0x6d, 0x65, 0xff,
      0xff, 0xff, 0xff, 0x66, 0x77, 0x75, 0x70, 0x64,
    )
    await this.ccid.transmit(Request.make(0x01, 0xa4, 0x04, 0x00, magic))
    // firmware upgrade trigger
    await this.ccid.transmit(Request.make(0x01, 0x55, 0x55, 0x55, 0x00))
  }

  async leave() {
    await this.ccid.transmit(Request.make(0xaa, 0x00, 0x00, 0x00, 0x00))
  }

  async [Symbol.asyncDispose]() {
    await this.leave()
  }
}
