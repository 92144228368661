export const enum CommandType {
  Status = 0x00,
  EmitLED = 0x01,
  Claim = 0x02,
  Power = 0x03,
  Transmit = 0x04,
  eSTKmeRecovery = 0xf0,
}

const commandTypes = [
  CommandType.Status,
  CommandType.EmitLED,
  CommandType.Claim,
  CommandType.Power,
  CommandType.Transmit,
  CommandType.eSTKmeRecovery,
]

export class Command {
  type: CommandType
  payload: Uint8Array

  static from(packet: Uint8Array) {
    const view = new DataView(packet.buffer)
    const type = view.getUint8(0)
    const length = view.getUint16(1, true)
    const payload = view.buffer.slice(3, 3 + length)
    return new Command(type, new Uint8Array(payload))
  }

  constructor(type: CommandType, payload: Uint8Array) {
    this.type = type
    this.payload = payload
    assert(this)
  }

  valueOf() {
    assert(this)
    const length = this.payload.byteLength
    const command = new Uint8Array(3 + length)
    command.set(Uint8Array.of(this.type, length & 0xff, (length >> 8) & 0xff), 0)
    command.set(this.payload, 3)
    return command
  }

  get [Symbol.toStringTag]() {
    return 'Command'
  }
}

function assert(command: Command) {
  if (!commandTypes.includes(command.type)) {
    throw new Error('Invalid command type.')
  }
  if (command.payload.length > 0xffff) {
    throw new Error('Payload size exceeds the maximum allowed length of 65535 bytes.')
  }
}
