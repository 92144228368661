import { ChipInfo, DownloadProfile, EUICCService, Notification, Profile } from './EUICCService'
import { NotCCID } from './NotCCID'
import { DFUMode } from './NotCCID/ESTKme'
import { RemoteLPACSocket } from './RemoteLPACSocket'
import { toHexString } from './utils'

export class RemoteLPACService extends EventTarget implements EUICCService {
  readonly ccid: NotCCID
  readonly socket: RemoteLPACSocket

  constructor(ccid: NotCCID, endpoint: string) {
    super()
    this.ccid = ccid
    this.socket = new RemoteLPACSocket(ccid, endpoint)
  }

  get atr() {
    return toHexString(this.ccid.atr)
  }

  async connect() {
    await this.ccid.claim()
    await this.ccid.powerOnCard(true)
  }

  async disconnect() {
    await this.ccid.powerOffCard()
    await this.ccid.release()
  }

  async chipInfo(): Promise<ChipInfo> {
    return this.socket.call('chip', 'info')
  }

  async purge(): Promise<void> {
    return this.socket.call('chip', 'purge', 'yes')
  }

  async setDefaultSMDP(host: string): Promise<void> {
    return this.socket.call('chip', 'defaultsmdp', host)
  }

  async *listProfile(): AsyncGenerator<Profile, void, void> {
    const profiles = await this.socket.call<Profile[]>('profile', 'list')
    for (const profile of profiles) {
      yield profile
    }
  }

  async enableProfile(profile: Profile): Promise<void> {
    return this.socket.call('profile', 'enable', profile.iccid)
  }

  async disableProfile(profile: Profile): Promise<void> {
    return this.socket.call('profile', 'disable', profile.iccid)
  }

  async deleteProfile(profile: Profile): Promise<void> {
    return this.socket.call('profile', 'delete', profile.iccid)
  }

  async setProfileName(profile: Profile, name: string): Promise<void> {
    return this.socket.call('profile', 'nickname', profile.iccid, name)
  }

  async *downloadProfile(options: DownloadProfile.Options = {}): AsyncGenerator<DownloadProfile.Progress, void, void> {
    const opts: string[] = ['profile', 'download']
    if (options.smdp) opts.push('-s', options.smdp)
    if (options.matchingId) opts.push('-m', options.matchingId)
    if (options.imei) opts.push('-i', options.imei)
    if (options.confirmationCode) opts.push('-c', options.confirmationCode)
    for await (const request of this.socket.execute(...opts)) {
      switch (request.type) {
        case 'progress':
          yield { type: request.payload.message }
          break
        case 'lpa':
          if (request.payload.code === 0) continue
          throw new Error(request.payload.message)
      }
    }
    return
  }

  async discoveryProfile() {
    return this.socket.call<void>('profile', 'discovery')
  }

  async *listNotification(): AsyncGenerator<Notification, void, void> {
    const notifications = await this.socket.call<Notification[]>('notification', 'list')
    for (const notification of notifications) {
      yield notification
    }
  }

  async *sendNotificationList(notifications: readonly Notification[]): AsyncGenerator<Notification> {
    if (notifications.length === 0) return
    const ids = notifications.map((n) => n.seqNumber)
    const unsentNotifications = Array.from(notifications)
    for await (const request of this.socket.execute('notification', 'process', ...ids)) {
      if (request.type !== 'progress') continue
      if (request.payload.message !== 'es10b_retrieve_notifications_list') continue
      yield unsentNotifications.shift()!
    }
  }

  async *removeNotificationList(notifications: readonly Notification[]): AsyncGenerator<Notification> {
    if (notifications.length === 0) return
    const ids = notifications.map((n) => n.seqNumber)
    const undeletedNotifications = Array.from(notifications)
    for await (const request of this.socket.execute('notification', 'remove', ...ids)) {
      if (request.type !== 'progress') continue
      if (request.payload.message !== 'es10b_remove_notification_from_list') continue
      yield undeletedNotifications.shift()!
    }
  }
}
