import { Mutex } from 'async-mutex'
import { NotCCID } from '../NotCCID'
import { LPASocket } from './LPASocket'
import { Request } from './STDIO'
export type * as STDIO from './STDIO'

export class RemoteLPACSocket {
  private readonly ccid: NotCCID
  private readonly endpoint: string
  #mutex = new Mutex()

  constructor(ccid: NotCCID, endpoint: string) {
    this.ccid = ccid
    this.endpoint = endpoint
    Object.freeze(this)
  }

  async call<T>(...args: unknown[]): Promise<T> {
    for await (const request of this.execute<T>(...args)) {
      if (request.type !== 'lpa') {
        continue
      }
      if (request.payload.code === -1) {
        throw new Error(request.payload.message)
      }
      return request.payload.data
    }
    throw new Error('Unexpected end of request')
  }

  async *execute<T>(...args: unknown[]): AsyncGenerator<Request<T>> {
    const release = await this.#mutex.acquire()
    try {
      yield* LPASocket.execute<T>(this.endpoint, this.ccid, args.map(String))
    } finally {
      release()
    }
  }
}
