import { configureStore } from '@reduxjs/toolkit'
import { getService as getEUICCService, setBackend as setEUICCBackend } from './euiccService'
import { euiccSlice } from './euiccSlice'

export const store = configureStore({
  reducer: {
    euicc: euiccSlice.reducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      thunk: {
        extraArgument: {
          getEUICCService,
          setEUICCBackend,
        },
      },
    })
  },
})

export interface ExtraArgument {
  getEUICCService: typeof getEUICCService
  setEUICCBackend: typeof setEUICCBackend
}

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
