import { Request, Response } from '../APDU'
import { Command, CommandType } from '../Command'
import type { Backend } from '../types'

const DEFAULT_IGNORE_TYPES = [CommandType.Status, CommandType.EmitLED]

export class ConsoleBackend implements Backend {
  private parent: Backend
  private ignoreTypes: ReadonlySet<CommandType>

  constructor(parent: Backend, ignoreTypes = DEFAULT_IGNORE_TYPES) {
    this.parent = parent
    this.ignoreTypes = new Set(ignoreTypes)
  }

  get connected(): boolean {
    return this.parent.connected
  }

  async invoke(request: Uint8Array): Promise<Uint8Array> {
    this.before(Command.from(request), '<-')
    const response = await this.parent.invoke(request)
    this.after(Command.from(response), '->')
    return response
  }

  private before(request: Command, direction: string) {
    if (this.ignoreTypes.has(request.type)) return
    const payload = request.payload
    if (request.type !== CommandType.Transmit) {
      console.info(getTypeName(request.type), direction, toHexString(payload))
      return
    }
    {
      const parsed = new Request(payload)
      const parts = [Array.from(parsed.slice(0, 5)).map(toHexString).join(' ')]
      if (parsed.data) parts.push(toHexString(parsed.data))
      console.info(direction, parts.join(`\n${direction} `))
    }
  }

  private after(response: Command, direction: string) {
    if (this.ignoreTypes.has(response.type)) return
    const payload = response.payload
    if (response.type !== CommandType.Transmit) {
      console.info(getTypeName(response.type), direction, toHexString(payload))
      return
    }
    {
      const parsed = new Response(payload)
      const parts = [`${toHexString(parsed.sw1)} ${toHexString(parsed.sw2)}`]
      if (parsed.data.byteLength > 0) parts.push(toHexString(parsed.data))
      console.info(direction, parts.join(`\n${direction} `))
    }
  }

  close(options?: Backend.CloseOptions): Promise<void> {
    return this.parent.close(options)
  }

  toString(): string {
    return this.parent.toString()
  }

  [Symbol.asyncDispose]() {
    return this.close()
  }

  get [Symbol.toStringTag](): string {
    return 'ConsoleBackend'
  }
}

function getTypeName(type: CommandType) {
  switch (type) {
    case CommandType.Status:
      return 'status'
    case CommandType.EmitLED:
      return 'emit-led'
    case CommandType.Claim:
      return 'claim'
    case CommandType.Power:
      return 'power'
    case CommandType.Transmit:
      return 'transmit'
    case CommandType.eSTKmeRecovery:
      return 'enter-recovery-mode'
  }
}

function toHexString(data: number | Uint8Array): string {
  if (typeof data === 'number') data = Uint8Array.of(data)
  return Array.from(data)
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
}
