import { EUICCService } from '../service/EUICCService'
import { ConsoleBackend, Backend, NotCCID } from '../service/NotCCID'
import { RemoteLPACService } from '../service/RemoteLPACService'
import { NotCCIDStatusTimer } from '../service/NotCCID/NotCCIDStatusTimer'

let service: EUICCService | null = null
const timer = new NotCCIDStatusTimer()

export function getService(): EUICCService | null {
  return service
}

export function setBackend(backend: Backend) {
  timer.stop()
  if (backend === null) {
    service = null
    return
  }
  // if (process.env.NODE_ENV === 'development') {
  //   backend = new ConsoleBackend(backend)
  // }
  const ccid = new NotCCID(new ConsoleBackend(backend))
  service = new RemoteLPACService(ccid, getRemoteEndPoint())
  timer.start(ccid)
}

function getRemoteEndPoint() {
  // prettier-ignore
  // const endpoint = process.env.NODE_ENV === 'development'
  //   ? new URL('/lpac', location.href)
  //   : new URL(process.env.LPAC_SOCKET_PROD!)
  const endpoint = new URL(process.env.LPAC_SOCKET_PROD!)
  endpoint.protocol = location.protocol === 'https:' ? 'wss:' : 'ws:'
  return endpoint.toString()
}
