import { ComponentType, createContext, createElement, FC, ReactNode, useContext, useState } from 'react'

interface Controller {
  opened: boolean
  open<P extends object>(type: ComponentType<P>, props: P): void
  close(): void
}

const ModalContext = createContext<Controller | null>(null)

interface ModalProviderProps {
  readonly children: ReactNode
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [stack, setStack] = useState<JSX.Element>()
  const value: Controller = {
    opened: stack !== undefined,
    open: (type, props) => {
      const modal = createElement(type, props)
      setStack(modal)
    },
    close: () => {
      setStack(undefined)
    },
  }
  return createElement(ModalContext.Provider, { value }, children, stack)
}

export function useModalController() {
  const controller = useContext(ModalContext)
  if (controller === null) {
    throw new Error('useModalController must be used inside a ModalProvider')
  }
  return controller
}
