/// <reference types="@types/web-bluetooth" />
import { concat } from '../../utils'
import type { Backend } from '../types'
import { getUint16 } from '../utils'

const SEGMENT_SIZE = 2 << 8 // 512 bytes
const SERIAL_SERVICE = 0x4553
const SERIAL_RX_CHARACTERISTIC = 0x544b
const SERIAL_TX_CHARACTERISTIC = 0x6d65

export class WebBLEBackend implements EventListenerObject, Backend {
  static async requestDevice() {
    if (!(await this.getAvailability())) throw new Error('WebBLE is not available')
    const filters: BluetoothLEScanFilter[] = [
      // product name
      { namePrefix: 'ESTKme-RED' },
      // fallback
      { name: 'XM-BLE-DEV' },
    ]
    if (navigator.userAgent.includes('Bluefy')) {
      return navigator.bluetooth.requestDevice({
        filters,
      })
    }
    return navigator.bluetooth.requestDevice({
      filters,
      optionalServices: [SERIAL_SERVICE],
    })
  }

  static async getAvailability() {
    if (!('bluetooth' in navigator)) return false
    if (!('getAvailability' in navigator.bluetooth)) return false
    return navigator.bluetooth.getAvailability()
  }

  static async open(device: BluetoothDevice): Promise<WebBLEBackend> {
    if (device.gatt === undefined) throw new Error('Bluetooth GATT is not available')
    const gatt = await device.gatt.connect()
    const service = await gatt.getPrimaryService(SERIAL_SERVICE)
    const tx = await service.getCharacteristic(SERIAL_TX_CHARACTERISTIC)
    const rx = await service.getCharacteristic(SERIAL_RX_CHARACTERISTIC)
    await rx.startNotifications()
    return new WebBLEBackend(service, tx, rx)
  }

  private readonly tx: CharacteristicValue
  private readonly rx: CharacteristicValue
  private readonly abortController = new AbortController()

  private constructor(
    private readonly service: BluetoothRemoteGATTService,
    tx: BluetoothRemoteGATTCharacteristic,
    rx: BluetoothRemoteGATTCharacteristic,
  ) {
    service.device.addEventListener('gattserverdisconnected', this)
    this.tx = new CharacteristicValue(tx, this.abortController.signal)
    this.rx = new CharacteristicValue(rx, this.abortController.signal)
  }

  handleEvent(event: Event): void {
    if (event.type === 'gattserverdisconnected') {
      this.abortController.abort('discoonnect')
    }
  }

  get connected() {
    if (this.gatt) return this.gatt.connected
    return false
  }

  get gatt() {
    return this.device.gatt
  }

  get device() {
    return this.service.device
  }

  async invoke(request: Uint8Array) {
    await this.tx.writeValue(request, SEGMENT_SIZE)
    return await this.rx
  }

  async close(options?: Backend.CloseOptions) {
    this.service.device.removeEventListener('gattserverdisconnected', this)
    this.abortController.abort('close')
    await this.tx[Symbol.asyncDispose]()
    await this.rx[Symbol.asyncDispose]()
    if (this.gatt?.connected) this.gatt.disconnect()
    if (options?.forget) await this.device.forget()
    return
  }

  [Symbol.asyncDispose]() {
    return this.close()
  }

  get [Symbol.toStringTag]() {
    return 'WebBLEBackend'
  }

  toString() {
    return `WebBLE:${this.device.name}`
  }
}

class CharacteristicValue implements EventListenerObject {
  private resolve: ((value: Uint8Array) => void) | undefined
  private reject: ((reason: unknown) => void) | undefined
  private packet = new Uint8Array(0)

  constructor(private readonly chara: BluetoothRemoteGATTCharacteristic, private readonly signal: AbortSignal) {
    this.chara.addEventListener('characteristicvaluechanged', this)
    this.signal.addEventListener('abort', this)
  }

  get expectedLength() {
    if (this.packet.byteLength < 3) return Number.NaN
    return 3 + getUint16(this.packet, 1)
  }

  async writeValue(request: Uint8Array, chunkSize: number) {
    for (let offset = 0; offset < request.byteLength; offset += chunkSize) {
      this.signal.throwIfAborted()
      await this.chara.writeValue(request.slice(offset, offset + chunkSize))
    }
  }

  handleEvent(event: Event) {
    if (event.type === 'abort' && this.reject) {
      this.reject(this.signal.reason)
      this.resolve = undefined
      this.reject = undefined
    }
    if (event.type === 'characteristicvaluechanged' && this.resolve) {
      if (this.signal.aborted) return
      this.packet = concat(this.packet, new Uint8Array(this.chara.value!.buffer))
      if (this.packet.byteLength < this.expectedLength) return
      this.resolve(this.packet)
      this.resolve = undefined
      this.reject = undefined
      this.packet = new Uint8Array(0)
    }
  }

  then(resolve: typeof this.resolve, reject: typeof this.reject) {
    this.signal.throwIfAborted()
    this.resolve = resolve
    this.reject = reject
  }

  async [Symbol.asyncDispose]() {
    this.signal.removeEventListener('abort', this)
    this.chara.removeEventListener('characteristicvaluechanged', this)
    await this.chara.stopNotifications()
  }
}
