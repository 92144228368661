export class EventIterator<T, TReturn = void> implements AsyncIterable<T> {
  private queue: Array<IteratorResult<T, TReturn>> = []

  async *[Symbol.asyncIterator](): AsyncIterator<T, TReturn> {
    if (Object.isFrozen(this.queue)) {
      throw new Error('The iterator has already been done')
    }
    let result: ReturnType<typeof this.queue.shift>
    while ((result = this.queue.shift()) !== undefined) {
      if (result.done) {
        return result.value
      }
      yield result.value
    }
    while (true) {
      result = await this
      if (result.done) {
        Object.freeze(this.queue)
        return result.value
      }
      yield result.value
    }
  }

  emit = (value: T) => this.resolve({ value, done: false })

  done = (value: TReturn) => this.resolve({ value, done: true })

  then(resolve: typeof this.resolve) {
    this.resolve = resolve
  }

  private resolve(result: IteratorResult<T, TReturn>) {
    if (Object.isFrozen(this.queue)) return
    this.queue.push(result)
    if (result.done) Object.freeze(this.queue)
  }
}
