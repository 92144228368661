import { Request } from './APDU'
import { NotCCID } from './NotCCID'

export async function openLogicChannel(ccid: NotCCID) {
  const response = await ccid.transmit(Request.make(0x00, 0x70, 0x00, 0x00, 0x01))
  if (!(response.ok && response.length === 3)) throw new Error('select logic channel failed')
  return response[0]
}

export async function closeLogicChannel(ccid: NotCCID, channel: number) {
  await ccid.transmit(Request.make(0x00, 0x70, 0x80, channel, 0x00))
}

export async function SELECT_AID(ccid: NotCCID, channel: number, aid: Uint8Array) {
  const response = await ccid.transmit(Request.make(channel, 0xa4, 0x04, 0x00, aid, 0x00))
  if (!(response.ok || response.hasMore)) throw new Error('open logic channel failed')
}
