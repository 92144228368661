import { NotCCID } from '../NotCCID'
import { SELECT_AID, openLogicChannel } from '../NotCCID/3GPP'
import { fromHexString, toHexString } from '../utils'
import { EventIterator } from './EventIterator'
import type { APDURequest, APDUResponse, ManagedRequest, Request, Response } from './STDIO'

const channels = new Map<NotCCID, number>()

export class LPASocket<T> implements EventListenerObject, AsyncIterable<Request<T>> {
  private readonly socket: WebSocket
  private readonly events = new EventIterator<Request<T>>()

  static async *execute<T>(endpoint: string, ccid: NotCCID, args: readonly string[]) {
    for await (const request of new LPASocket<T>(endpoint, ccid, args)) {
      yield request
    }
  }

  private constructor(endpoint: string, private readonly ccid: NotCCID, private readonly args: readonly string[]) {
    this.socket = new WebSocket(endpoint)
    this.socket.addEventListener('open', this)
    this.socket.addEventListener('close', this)
    this.socket.addEventListener('error', this)
    this.socket.addEventListener('message', this)
  }

  [Symbol.asyncIterator](): AsyncIterator<Request<T>> {
    return this.events[Symbol.asyncIterator]()
  }

  handleEvent(event: Event) {
    if (event.type === 'open') {
      this.send(this.args)
      console.groupCollapsed('lpac', ...this.args)
    } else if (event.type === 'close' || event.type === 'error') {
      this.handleClose()
      console.groupEnd()
    } else if (event.type === 'message') {
      this.handleMessage(event as MessageEvent)
    }
  }

  private send<T>(value: T) {
    this.socket.send(JSON.stringify(value))
  }

  private async handleMessage(event: MessageEvent) {
    const request: ManagedRequest<T> = JSON.parse((event as MessageEvent).data)
    if (request.type === 'apdu') {
      let payload: APDUResponse
      try {
        payload = await this.handleAPDUMessage(request.payload)
      } catch {
        payload = { ecode: -1 }
      }
      this.send<Response>({ type: 'apdu', payload })
    } else if (request.type === 'progress') {
      this.events.emit(request)
      console.info(request.type, request.payload.message)
    } else if (request.type === 'lpa') {
      this.events.emit(request)
      console.groupCollapsed(request.type)
      if (Array.isArray(request.payload.data)) {
        for (const element of request.payload.data) {
          console.info(element)
        }
      } else {
        console.info(request.payload.data)
      }
      console.groupEnd()
    }
  }

  private handleClose() {
    this.socket.removeEventListener('open', this)
    this.socket.removeEventListener('close', this)
    this.socket.removeEventListener('error', this)
    this.socket.removeEventListener('message', this)
    this.events.done()
  }

  private async handleAPDUMessage(payload: APDURequest): Promise<APDUResponse> {
    try {
      switch (payload.func) {
        case 'logic_channel_open':
          let channel = channels.get(this.ccid)
          if (channel !== undefined) return { ecode: channel }
          console.groupCollapsed(payload.func)
          channel = await openLogicChannel(this.ccid)
          await SELECT_AID(this.ccid, channel, fromHexString(payload.param))
          console.groupEnd()
          return { ecode: channel }
        case 'transmit':
          console.groupCollapsed(payload.func)
          const request = fromHexString(payload.param)
          const response = await this.ccid.transmit(request)
          console.groupEnd()
          return { ecode: 0, data: toHexString(response) }
      }
      return { ecode: 0 }
    } catch {
      return { ecode: -1 }
    }
  }
}
