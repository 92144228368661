import { NotCCID } from './NotCCID'

export class NotCCIDStatusTimer {
  private abortController = new AbortController()

  private get signal() {
    return this.abortController.signal
  }

  start(ccid: NotCCID) {
    this.abortController = new AbortController()
    setTimeout(this.handlePoll, 0, ccid)
  }

  stop() {
    this.abortController.abort()
  }

  private handlePoll = async (ccid: NotCCID) => {
    if (this.signal.aborted) return
    try {
      const status = await ccid.getStatus()
      await ccid.emitLED(status.cardInserted ? 0x00ff00 : 0xff0000)
      setTimeout(this.handlePoll.bind(this, ccid), status.cardInserted ? 1000 : 500)
    } catch {
      // ignore
    }
  }
}
